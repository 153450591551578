<template>
  <section v-loading="loading" class="attachment-popup-body">
    <div class="attachment-upload-button justify-space-between">
      <div class="category-select" v-if="!isCaseCompleted">
        <el-select
          clearable
          filterable
          default-first-option
          placeholder="Select Category"
          v-model="category"
          allow-create
        >
          <el-option
            v-for="attach in this.attachmentsOptions"
            :label="attach"
            :value="attach"
            :key="attach"
          ></el-option>
        </el-select>
      </div>
      <el-upload
        name="logo"
        action
        :on-change="uploadFile"
        :show-file-list="false"
        :auto-upload="false"
      >
        <el-button
          class="btn radius-button round-button"
          v-if="!isCaseCompleted"
        >
          <span>
            <img
              src="@/assets/images/icons/attachment-icon-v2.svg"
              alt=""
            /> </span
          >SELECT FILE</el-button
        >
      </el-upload>
    </div>
    <!-- Attachment upload -->
    <div class="attachment-upload" v-loading="fileUploadLoading">
      <a
        v-if="selectedFileName"
        :element-loading-text="loadingText"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <div class="content">
          <div class="icon">
            <img
              src="@/assets/images/icons/attachments/attachments_0.svg"
              alt="icon"
              class="img-fluid"
            />
          </div>
          <div class="text">
            <p>
              {{ selectedFileName }}
            </p>
          </div>
        </div>
        <ul class="actions">
          <li>
            <el-button @click="remove" class="remove-button">
              <el-icon><close /></el-icon>
            </el-button>
          </li>
          <li>
            <el-button
              class="upload-button"
              @click="uploadSelectedDocument"
              :disabled="!selectedFile ? true : false"
            >
              <el-icon><Upload /></el-icon>
            </el-button>
          </li>
        </ul>
      </a>
    </div>

    <div v-if="!noAttachments" class="attachment-uploaded">
      <ul>
        <li v-for="(doc, index) of allAttachments" :key="index">
          <div class="content" @click="downloadAttachment(doc)">
            <div class="icon">
              <img
                src="@/assets/images/icons/attachments/attachments_0.svg"
                alt="icon"
                class="img-fluid"
              />
            </div>
            <p class="text">
              <b style="color: blue">{{
                doc.category ? doc.category + " -" : ""
              }}</b>
              {{
                doc.metadata && doc.metadata.original_name
                  ? doc.metadata.original_name
                  : ""
              }}
            </p>
          </div>
          <button
            class="remove-button"
            type="button"
            @click="onDeleteAttachment(doc)"
          >
            <p>Remove</p>
          </button>
        </li>
      </ul>
    </div>
    <div
      class="attachments-empty"
      v-if="noAttachments && !this.selectedFileName"
    >
      <p>You Can manage attachments here</p>
    </div>
    <div class="mini-reason-for-change">
      <ResonForChange
        v-if="takeReasonForChangeDialog"
        v-on:close="takeReasonForChangeDialog = false"
        v-on:reasonSubmit="deleteAttachment"
        title="Reason for Remove"
      ></ResonForChange>
    </div>
  </section>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { mapGetters } from "vuex";
import UserPermissionsHelper from "@/mixins/UserPermissionsHelper";
const ResonForChange = defineAsyncComponent(() =>
  import("@/components/misc/ResonForChange")
);
import moment from "moment";
import {
  successNotification,
  errorNotification,
} from "@/helpers/notifications";
import caseConstants from "@/config/constants/caseConstants";
export default {
  name: "Attachments",
  mixins: [UserPermissionsHelper],
  components: {
    ResonForChange,
  },
  computed: {
    ...mapGetters("orders", [
      "getFileUploadURLForAttachments",
      "getFileUploadRefIdForAttachment",
      "getAttachmentUploadStatusUpdated",
      "getAttachmentUploadStatus",
      "getAttachmentDeleteStatus",
      "getCaseInformation",
    ]),
    ...mapGetters("respiratoryFileUpload", ["getFileDownloadUrl"]),
    isCaseCompleted() {
      if (!this.getCaseInformation) {
        return false;
      }
      return this.getCaseInformation.status === "COMPLETE";
    },
  },

  mounted() {
    this.checkCaseInformation();
  },
  data() {
    return {
      noAttachments: false,
      selectedFile: null,
      selectedFileName: null,
      orderId: "",
      allAttachments: [],
      loadingText: "",
      loading: false,
      fileUploadLoading: false,
      takeReasonForChangeDialog: false,
      selectedNote: null,
      attachmentsOptions: caseConstants.ATTACHMENTS_DROPDOWN,
      category: "",
    };
  },
  methods: {
    onDeleteAttachment(doc) {
      this.takeReasonForChangeDialog = true;
      this.selectedNote = doc;
    },

    uploadFile(file) {
      this.selectedFile = file.raw;
      this.selectedFileName = file.raw.name;
      this.getFileUploadUrl(file);
    },
    remove() {
      (this.selectedFile = null), (this.selectedFileName = "");
    },
    async getFileUploadUrl(file) {
      try {
        this.loading = true;
        let data = {
          file: file.raw.name,
          file_type: file.raw.type,
          file_size: file.raw.size / 1000,
          order_id: this.orderId,
          type: "case-attachments-upload",
        };
        if (this.category) {
          data.category = this.category;
        }
        await this.$store.dispatch("orders/fetchDocumentUploadUrl", data);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    async uploadSelectedDocument() {
      this.fileUploadLoading = true;
      this.loadingText = "Uploading Document...";
      // uploding to S3
      let params = {
        file: this.selectedFile,
        upload_url: this.getFileUploadURLForAttachments,
      };

      await this.$store.dispatch("orders/uploadDocumentToGeneratedUrl", params);

      if (this.getAttachmentUploadStatus) {
        // UPDATEING STATUS
        console.log(this.orderId);
        let params = {
          hash: this.getFileUploadRefIdForAttachment,
          order_id: this.orderId,
          type: "case-attachments-upload",
        };
        await this.$store.dispatch("orders/updateDocumentUploadStatus", params);
        if (this.getAttachmentUploadStatusUpdated) {
          successNotification("Attachment added successfully");
          this.selectedFileName = "";
          this.selectedFile = null;
          await this.checkCaseInformation();
        } else {
          this.$message("Sorry ! Error while file uploading");
        }
      } else {
        this.$message("Sorry ! Error while file uploading");
      }
      this.fileUploadLoading = false;
    },

    async deleteAttachment(reason) {
      let params = {
        order_id: this.orderId,
        id: this.selectedNote._id,
        data_update_reason: reason,
      };

      await this.$store.dispatch("orders/deleteAttachment", params);

      if (this.getAttachmentDeleteStatus) {
        successNotification("Attachment deleted successfully");
        this.checkCaseInformation();
      }
    },

    async downloadAttachment(doc) {
      let routerData = this.$router.resolve({
        name: "ViewAttachments",
        params: {
          path: doc.path,
        },
      });
      window.open(routerData.href, "_blank");
    },

    async checkCaseInformation() {
      try {
        this.loading = true;
        this.fileUploadLoading = false;
        this.orderId = this.$route.params.order_id;
        if (!this.orderId) {
          this.loading = false;
          this.$router.push({
            to: "/cases",
          });
        } else {
          await this.$store.dispatch("orders/fetchCaseInformation", {
            order_id: this.orderId,
          });
          if (!this.getCaseInformation) {
            this.disablePatientInformation = true;
            this.showTertiaryInsuranceForm = true;
          }

          if (this.getCaseInformation && this.getCaseInformation.attachments) {
            this.allAttachments = this.getCaseInformation.attachments || [];
            this.allAttachments = this.allAttachments.sort(
              (a, b) => moment(b.created_at) - moment(a.created_at)
            );
            this.noAttachments = this.allAttachments.length ? false : true;
          }
        }
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="scss">
.mini-reason-for-change {
  .el-dialog {
    width: 28%;
    height: 210px !important;
    margin-top: 20vh;
  }
}
</style>
