
export default {
  BILLING_TYPES: ["INSURANCE", "PATIENT", "CLIENT", 'LABCORP',],
  CASE_TYPES: [
    "CLINICAL_CHEMISTRY",
    "PGX_TEST",
    "RESPIRATORY_PATHOGEN_PANEL",
    "COVID",
    "UTI",
    "WOUND",
    "CARDIAC",
    "PULMONARY_PANEL",
    "CGX_PANEL",
    "DIABETES",
    "COVID_FLU",
    "GTI_WOMENS_HEALTH",
    "GTI STI",
    "WOMENS_HEALTH",
    "STI"
  ],
  CASE_STATUSES: ["ACTIVE", "COMPLETE", "INACTIVE", "ARCHIVED", "TEST_REPORTS"],
  ORDER_STATUSES: [
    "ACTIVE",
    "COMPLETE",
    "INACTIVE",
    "ARCHIVED",
    "CASE_CREATED",
    "CANCELLED",
  ],
  BILLING_RELATIONSHIPS: [
    "Adopted Child",
    "Cadaver Donor",
    "Child",
    "Child Where Insured Has No Financial Responsibility",
    "Dependent of a Minor Dependent",
    "Emancipated Minor",
    "Employee",
    "Father",
    "Foster Child",
    "Grandfather or Grandmother",
    "Grandson or Granddaughter",
    "Handicapped Dependent",
    "Injured Plaintiff",
    "Life Partner",
    "Mother",
    "Nephew or Niece",
    "Organ Donor",
    "Other Adult",
    "Other Relationship",
    "Self",
    "Significant Other",
    "Sponsored Dependent",
    "Spouse",
    "Stepson or Stepdaughter",
    "Unknown",
    "Ward",
  ],
  BILLING_GENDER_TYPES: ["MALE", "FEMALE", "OTHERS"],
  BILLING_INSURANCE_PLAN_TYPES: ["INDIVIDUAL", "FAMILY"],
  CASE_TYPE_MAP: {
    PGX_TEST: "PGx",
    CLINICAL_CHEMISTRY: "Clinical Chemistry",
    RESPIRATORY_PATHOGEN_PANEL: "Respiratory",
    TOXICOLOGY: "Toxicology",
    COVID: "Covid",
    UTI: "UTI",
    WOUND: "Wound",
    CARDIAC: "Cardiac",
    PULMONARY_PANEL: "Pulmonary",
    CGX_PANEL: "CGx",
    PAD_ALZHEIMERS: "PAD (Alzheimers)",
    DIABETES: "Diabetes",
    COVID_FLU: "Covid and Flu",
    URINALYSIS: "Urinalysis",
    NAIL: "Nail",
    GASTRO: "Gastro",
    GTI_WOMENS_HEALTH: "GTI WOMEN'S HEALTH",
    GTI_STI: "GTI STI",
    WOMENS_HEALTH: "WOMENS HEALTH",
    STI: "STI"
  },
  // case_name and label for display purpose
  // reporting is used to select the panel(case type) in uploading result files
  // report_selection is used for getting gened test results
  ALL_CASE_TYPES_DATA: [
    {
      label: "All",
      value: "ALL",
    },
    {
      value: "CARDIAC",
      label: "Cardiac",
    },
    {
      value: "CGX_PANEL",
      label: "CGx",
    },
    {
      label: "Clinical Chemistry",
      value: "CLINICAL_CHEMISTRY",
    },
    {
      label: "Covid",
      value: "COVID",
    },
    {
      label: "Covid and Flu",
      value: "COVID_FLU",
    },
    {
      value: "DIABETES",
      label: "Diabetes",
    },
    {
      value: "GASTRO",
      label: "Gastro",
    },
    {
      value: "PAD_ALZHEIMERS",
      label: "PAD (Alzheimers)",
    },
    {
      value: "PGX_TEST",
      label: "PGx",
    },
    {
      value: "PULMONARY_PANEL",
      label: "Pulmonary",
    },
    {
      value: "NAIL",
      label: "Nail",
    },
    {
      label: "Respiratory",
      value: "RESPIRATORY_PATHOGEN_PANEL",
    },
    {
      value: "TOXICOLOGY",
      label: "Toxicology",
    },
    {
      label: "UTI",
      value: "UTI",
    },
    {
      value: "WOUND",
      label: "Wound",
    },
    {
      label: "Urinalysis",
      value: "URINALYSIS",
    },
    {
      label: "GTI WOMEN'S HEALTH",
      value: "GTI_WOMENS_HEALTH",
    },
    {
      label: "GTI STI",
      value: "GTI_STI",
    },
    {
      label: "WOMENS HEALTH",
      value: "WOMENS_HEALTH"
    },
    {
      label: "STI",
      Value: "STI"
    }
  ],
  CASE_TYPES_DATA: [
    {
      case_name: "Cardiac",
      value: "CARDIAC",
      label: "Cardiac",
      reporting: false,
      report_selection: true,
    },
    {
      case_name: "CGx",
      value: "CGX_PANEL",
      label: "CGx",
      reporting: false,
      report_selection: false,
    },
    {
      case_name: "Clinical Chemistry",
      label: "Clinical Chemistry",
      value: "CLINICAL_CHEMISTRY",
      reporting: false,
      report_selection: false,
    },
    {
      case_name: "Covid",
      label: "Covid",
      value: "COVID",
      reporting: true,
      report_selection: false,
    },
    {
      case_name: "Covid and Flu",
      label: "Covid and Flu",
      value: "COVID_FLU",
      reporting: true,
      report_selection: false,
    },
    {
      case_name: "Diabetes",
      value: "DIABETES",
      label: "Diabetes",
      reporting: false,
      report_selection: false,
    },
    {
      case_name: "Gastro",
      value: "GASTRO",
      label: "Gastro",
      reporting: false,
      report_selection: false,
    },
    {
      case_name: "GTI STI",
      label: "GTI STI",
      value: "GTI_STI",
      reporting: false,
      report_selection: false,
    },
    {
      case_name: "GTI WOMEN'S HEALTH",
      label: "GTI WOMENS HEALTH",
      value: "GTI_WOMENS_HEALTH",
      reporting: false,
      report_selection: false,
    },
    {
      case_name: "PAD (Alzheimers)",
      value: "PAD_ALZHEIMERS",
      label: "PAD (Alzheimers)",
      reporting: false,
      report_selection: false,
    },
    {
      case_name: "PGx",
      value: "PGX_TEST",
      label: "PGx",
      reporting: false,
      report_selection: false,
    },
    {
      case_name: "Pulmonary",
      value: "PULMONARY_PANEL",
      label: "Pulmonary",
      reporting: false,
      report_selection: true,
    },
    {
      case_name: "Nail",
      value: "NAIL",
      label: "Nail",
      reporting: false,
      report_selection: false,
    },
    {
      case_name: "Respiratory",
      label: "Respiratory",
      value: "RESPIRATORY_PATHOGEN_PANEL",
      reporting: true,
      report_selection: false,
    },
    {
      case_name: "STI",
      label: "STI",
      value: "STI",
      reporting: false,
      report_selection: false,
    },
    {
      case_name: "Toxicology",
      value: "TOXICOLOGY",
      label: "Toxicology",
      reporting: true,
      report_selection: false,
    },
    {
      case_name: "UTI",
      label: "UTI",
      value: "UTI",
      reporting: true,
      report_selection: false,
    },
    {
      case_name: "WOMENS HEALTH",
      label: "WOMENS HEALTH",
      value: "WOMENS_HEALTH",
      reporting: false,
      report_selection: false,
    },
    {
      case_name: "Wound",
      value: "WOUND",
      label: "Wound",
      reporting: true,
      report_selection: false,
    },
    {
      case_name: "Urinalysis",
      label: "Urinalysis",
      value: "URINALYSIS",
      reporting: false,
      report_selection: false,
      is_genetic: false,
    },
  ],
  TEST_CASE_TYPES_DATA: [
    {
      case_name: "Clinical Chemistry",
      label: "Clinical Chemistry",
      value: "CLINICAL_CHEMISTRY",
      reporting: false,
      report_selection: false,
    },
    {
      case_name: "Cardiac",
      value: "CARDIAC",
      label: "Cardiac",
      reporting: false,
      report_selection: true,
    },
    {
      case_name: "CGx",
      value: "CGX_PANEL",
      label: "CGx",
      reporting: false,
      report_selection: false,
    },
    {
      case_name: "Covid",
      label: "Covid",
      value: "COVID",
      reporting: true,
      report_selection: false,
    },
    {
      case_name: "Covid and Flu",
      label: "Covid and Flu",
      value: "COVID_FLU",
      reporting: true,
      report_selection: false,
    },
    {
      case_name: "Diabetes",
      value: "DIABETES",
      label: "Diabetes",
      reporting: false,
      report_selection: false,
    },
    {
      case_name: "PAD (Alzheimers)",
      value: "PAD_ALZHEIMERS",
      label: "PAD (Alzheimers)",
      reporting: false,
      report_selection: false,
    },
    {
      case_name: "PGx",
      value: "PGX_TEST",
      label: "PGx",
      reporting: false,
      report_selection: false,
    },
    {
      case_name: "Pulmonary",
      value: "PULMONARY_PANEL",
      label: "Pulmonary",
      reporting: false,
      report_selection: true,
    },
    {
      case_name: "Respiratory",
      label: "Respiratory",
      value: "RESPIRATORY_PATHOGEN_PANEL",
      reporting: true,
      report_selection: false,
    },
    {
      case_name: "Toxicology",
      value: "TOXICOLOGY",
      label: "Toxicology",
      reporting: true,
      report_selection: false,
    },
    {
      case_name: "Urinalysis",
      label: "Urinalysis",
      value: "URINALYSIS",
      reporting: false,
      report_selection: false,
      is_genetic: false,
    },
  ],
  insurance: {
    relationship: "",
    plan_type: "",
    payer: "",
    payor: "",
    insurance_code: "",
    gender: "",
    date_of_birth: "",
    policy_plan: "",
    group_or_plan: "",
    group: "",
    patient_policy: "",
    address_line_1: "",
    address_line_2: "",
    city: "",
    state: "",
    zip: "",
    first_name: "",
    last_name: "",
    insurance_carrier_code: "",
    policy_id: "",
  },
  GENETIC_TESTS: [
    "PGX_TEST",
    "CGX_PANEL",
    "PAD_ALZHEIMERS",
    "DIABETES",
    "PULMONARY_PANEL",
    "CARDIAC",
    "GASTRO",
    "TOXICOLOGY",
    "RESPIRATORY_PATHOGEN_PANEL",
    "NAIL",
    "CLINICAL_CHEMISTRY",
    "WOUND",
    "UTI",
  ],
  GENETIC_TEST_CASE_TYPES: [
    "PGX_TEST",
    "CGX_PANEL",
    "PAD_ALZHEIMERS",
    "DIABETES",
    "PULMONARY_PANEL",
    "CARDIAC",
  ],
  DUPLICATE_CASE_TYPES: [
    "CLINICAL_CHEMISTRY",
    "COVID",
    "COVID_FLU",
    "URINALYSIS",
    "GTI_WOMENS_HEALTH",
    "GTI_STI",
    "WOMENS_HEALTH",
    "STI"
  ],
  NON_GENETIC_TEST_CASE_TYPES: [
    "RESPIRATORY_PATHOGEN_PANEL",
    "UTI",
    "TOXICOLOGY",
    "WOUND",
    "NAIL",
    "GASTRO",
  ],
  SENDOUT_CASE_TYPES_DATA: [
    {
      case_name: "Cardiac",
      value: "CARDIAC_PANEL",
      label: "Cardiac",
      reporting: false,
    },
    {
      case_name: "CGx",
      value: "CGX_PANEL",
      label: "CGx",
      reporting: false,
    },
    {
      case_name: "Clinical Chemistry",
      label: "Clinical Chemistry",
      value: "CLINICAL_CHEMISTRY",
      reporting: false,
    },
    {
      case_name: "Diabetes",
      value: "DIABETES",
      label: "Diabetes",
      reporting: false,
    },
    {
      case_name: "Gastro",
      value: "GASTRO",
      label: "Gastro",
      reporting: false,
    },
    {
      case_name: "PAD (Alzheimers)",
      value: "PAD_ALZHEIMERS",
      label: "PAD (Alzheimers)",
      reporting: false,
    },
    {
      case_name: "PGx",
      label: "PGx",
      value: "PGX_TEST",
      reporting: false,
    },
    {
      case_name: "Pulmonary",
      value: "PULMONARY_PANEL",
      label: "Pulmonary",
      reporting: false,
    },
    {
      case_name: "Nail",
      value: "NAIL",
      label: "Nail",
      reporting: false,
    },
    {
      case_name: "Respiratory",
      label: "Respiratory",
      value: "RESPIRATORY_PATHOGEN_PANEL",
      reporting: true,
    },
    {
      case_name: "Toxicology",
      value: "TOXICOLOGY",
      label: "Toxicology",
      reporting: true,
    },
    {
      case_name: "UTI",
      label: "UTI",
      value: "UTI",
      reporting: true,
    },
    {
      case_name: "Wound",
      value: "WOUND",
      label: "Wound",
      reporting: false,
    },
  ],

  CASE_STATUS: [
    {
      name: "ACTIVE",
      value: "ACTIVE",
    },
    {
      name: "FINALIZED",
      value: "COMPLETE",
    },
    {
      name: "PROCESSING",
      value: "TEST_REPORTS",
    },
  ],
  CASE_PHYSICIAN_STATUS: [
    {
      name: "PROCESSING",
      value: "ACTIVE,TEST_REPORTS",
    },
    {
      name: "FINALIZED",
      value: "COMPLETE",
    },
  ],
  EMR_ORDER_STATUS: [
    {
      name: "Completed",
      value: "COMPLETED",
    },
    {
      name: "Archived",
      value: "ARCHIVED",
    },
    {
      name: "Active",
      value: "ACTIVE",
    },
    {
      name: "Pending",
      value: "PENDING",
    },
  ],
  COVID_STATUS: [
    {
      label: "POSITIVE",
      value: "POSITIVE",
    },
    {
      label: "NEGATIVE",
      value: "NEGATIVE",
    },
    {
      label: "INCONCLUSIVE",
      value: "INCONCLUSIVE",
    },
    {
      label: "RESULT AWAITED",
      value: "RESULT_AWAITED",
    },
  ],
  STATUS_TYPE_MAP: {
    ACTIVE: "Active",

    COMPLETE: "Finalized",
    TEST_REPORTS: "Processing",
    CANCELLED: "Cancelled",
    INACTIVE: "Inactive",

    CASE_CREATED: "Case Created",
    ARCHIVED: "Archived",
    PENDING: "Pending",
    REJECTED: "Rejected",
    TO_BE_COLLECTED: "Future",
  },
  ethnicityOptions: [
    "Ashkenazi Jewish",
    "Asian",
    "Black/African",
    "American",
    "Hispanic or Latino",
    "Hispanic",
    "Native American",
    "Not Hispanic or Latino",
    "Other",
    "Pacific Islander",
    "Unknown",
    "White/Caucasian",
  ],
  genderOptions: ["MALE", "FEMALE", "OTHERS"],
  raceOptions: [
    "American Indian or Alaska Native",
    "Asian",
    "Black or Afrian American",
    "Native Hawalian or Other Pacific Islander",
    "White",
    "Other Race",
  ],

  CASE_TYPE_SPECIMEN_TYPE_MAP: {
    PGX_TEST: ["Buccal Swab"],
    COVID: ["Swab"],
    CLINICAL_CHEMISTRY: ["Blood", "Urine", "Stool", "Other"],
    RESPIRATORY_PATHOGEN_PANEL: ["Nasal Swab"],
    UTI: ["Urine", "Catheter Urine"],
    TOXICOLOGY: ["Oral", "Urine"],
    WOUND: ["Wound Swab", "Tissue"],
    CARDIAC: ["Buccal Swab"],
    PULMONARY_PANEL: ["Buccal Swab"],
    CGX_PANEL: ["Buccal Swab"],
    PAD_ALZHEIMERS: ["Buccal Swab"],
    DIABETES: ["Buccal Swab"],
    COVID_FLU: ["Swab"],
    URINALYSIS: ["Urine"],
    NAIL: ["Nail", "Nail & Tissues"],
    GASTRO: ["Fecal Swab"],
    GTI_WOMENS_HEALTH: ["Vaginal Swab", "Endometrial Swab"],
    GTI_STI: ["Vaginal Swab", "Endometrial Swab"],
    WOMENS_HEALTH: ["Vaginal Swab", "Endometrial Swab"],
    STI: ["Vaginal Swab", "Endometrial Swab"],
  },
  ATTACHMENTS_DROPDOWN: [
    "Requisition",
    "Patient Consent Form",
    "Insurance Card",
    "Patient ID Card",
    "Other",
  ],
  fontStyles: [
    {
      label: "Arial",
      value: "18px Arial",
    },
    {
      label: "Brush Script MT",
      value: "22px Brush Script MT",
    },
    {
      label: "Calibri",
      value: "20px Calibri",
    },
    {
      label: "Cambria",
      value: "18px Cambria",
    },
    {
      label: "Lato 25px",
      value: "25px Lato",
    },
    {
      label: "Roboto 25px",
      value: "25px Roboto",
    },
    {
      label: "Times New Roman",
      value: "18px Times New Roman",
    },
    {
      label: "Verdana",
      value: "25px Verdana",
    },
  ],
  BILLING_CATEGORIES: [
    { label: "Billed", value: "Billed" },
    { label: "Unbilled", value: "Claim process pending" },
    { label: "Unbilled", value: "Coding Pending" },
    { label: "Exception", value: "Policy Inactive" },
    { label: "Exception", value: "Invalid subscriber ID/ patient name" },
    { label: "Exception", value: "Missing/Invalid ICD" },
    { label: "Exception", value: "Requisition/Additional document missing" },
    { label: "Exception", value: "DOS mismatch" },
    { label: "Exception", value: "Provider/Patient signature missing" },
    { label: "Exception", value: "Panel not marked/Mismatch" },
    { label: "On hold", value: "CPT/insurance guidelines Hold" },
    { label: "On hold", value: "Credential hold" },
    { label: "On hold", value: "Deductible not met" },
    {
      label: "Billed with Exception",
      value: "Organisms mismatch b/w req and report",
    },
    { label: "Billed with Exception", value: "ABR not marked" },
    { label: "Billed with Exception", value: "Panel not marked/Mismatch" },
    { label: "Billed to Patient claim", value: "Paid to patient" },
    { label: "Billed to Patient claim", value: "Patient responsibility" },
    {
      label: "Billed to Patient claim",
      value: "Active/valid insurance details",
    },
  ],
  fontStyles: [
    {
      label: "Cabin",
      value: "25px Cabin",
    },
    {
      label: "Lato",
      value: "25px Lato",
    },
    {
      label: "Nunito",
      value: "25px Nunito",
    },
    {
      label: "Oswald",
      value: "25px Oswald",
    },

    {
      label: "Pacifico",
      value: "25px Pacifico",
    },
    {
      label: "Poppins",
      value: "25px Poppins",
    },
    {
      label: "Quicksand",
      value: "25px Quicksand",
    },
    {
      label: "Raleway",
      value: "25px Raleway",
    },
    {
      label: "Roboto",
      value: "25px Roboto",
    },
    {
      label: "Ubuntu",
      value: "25px Ubuntu",
    },
    {
      label: "Allura",
      value: "25px Allura",
    },
    {
      label: "Dancing Script",
      value: "25px Dancing Script",
    },
    {
      label: "Great Vibes",
      value: "25px Great Vibes",
    },
  ],
  FASTING: [
    {
      label: "Yes",
      value: true,
    },
    {
      label: "No",
      value: false,
    },
  ],
  TOX_CONFORMATION_TESTS: [
    "OXYCODONE",
    "TRAMADOL",
    "HYPNOTICS",
    "AMPHETAMINES",
    "COTININE",
    "BENZODIAZEPINE",
    "OPIOIDS_&_OPIATE_ANALOGS",
    "BARBITURATES",
    "PREGABALIN",
    "SKELETAL_MUSCLE_RELAXANTS",
    "ANTIDEPRESSANTS(TCAS)",
    "DEXTROMETHORPHAN",
    "FENTANYL",
    "6-MAM",
    "BUPRENORPHINE",
    "METHYLENEDIOXY-AMPHETAMINES",
    "STIMULANTS",
    "BENZOYLECGONINE",
    "METHADONE",
    "OPIATES",
    "O-NALOXONE",
    "TAPENTADOL",
    "NALTREXONE",
    "PROPOXYPHENE",
    "PCP",
    "GABAPENTIN",
    "OH_BUPROPION",
    "MITRAGYNINE",
    "XYLAZINE",
  ],
  TOX_CHECK_LIST: [
    { label: "Screen and Confirm Tests Selected Below" },
    { label: "Screen and Confirm All Negative and Positive Results" },
    { label: "Screen Only" },
    { label: "Confirm All Negative and Positive Results" },
    { label: "Confirm Tests Selected Below" },
    { label: "Screen Reflex to Confirmation" },
    { label: "Confirm All Prescribed Medications of Interest" },
  ],
  TOX_CATEGORY_TESTS: [
    "SCREENING",
    "ETG/ETS",
    "SCREENING_VALIDITY",
    "ORAL_FLUID_TOX_CONFIRMATION",
    "D_L_Isomer",
    "KRATOM",
    "WELLBUTRIN",
    "XYLAZINE",
    "OXYCODONE",
    "TRAMADOL",
    "HYPNOTICS",
    "AMPHETAMINES",
    "COTININE",
    "BENZODIAZEPINE",
    "OPIOIDS_&_OPIATE_ANALOGS",
    "BARBITURATES",
    "PREGABALIN",
    "SKELETAL_MUSCLE_RELAXANTS",
    "ANTIDEPRESSANTS(TCAS)",
    "DEXTROMETHORPHAN",
    "FENTANYL",
    "6-MAM",
    "BUPRENORPHINE",
    "METHYLENEDIOXY-AMPHETAMINES",
    "STIMULANTS",
    "BENZOYLECGONINE",
    "METHADONE",
    "OPIATES",
    "O-NALOXONE",
    "TAPENTADOL",
    "NALTREXONE",
    "PROPOXYPHENE",
    "PCP",
    "GABAPENTIN",
    "OH_BUPROPION",
    "MITRAGYNINE",
    "XYLAZINE",
  ],
  stateCarrierMapping: {
    "KENTUCKY": "SB660",
    "MASSACHUSETTS": "SB700",
    "ALABAMA": "SB510",
    "ARKANSAS": "SB520",
    "CALIFORNIA": "94036",
    "COLORADO": "SB550",
    "DAKOTA": "SB889",
    "FLORIDA": "SB590",
    "GEORGIA": "SB600",
    "MARYLAND": "SB690",
    "MEXICO": "SB790",
    "MICHIGAN": "32002",
    "MISSOURI": "SB741",
    "NEVADA": "SB765",
    "NEW JERSEY": "22099",
    "NEW YORK": "SB803",
    "NORTH CAROLINA": "SB810",
    "OHIO": "SB338",
    "OKLAHOMA": "SB840",
    "SHIELD": "SB910",
    "TENNESSEE": "SB890",
    "TEXAS": "SB900",
    "VIRGINIA": "SB923"
  }
  ,
  stateMedicaidCarrierMapping: {

    "ALABAMA": "SKAL0",
    "ARKANSAS": "SKAR0",
    "CONNECTICUT": "SKCT0",
    "FLORIDA": "SKFL0",
    "INDIANA": "SKIN0",
    "MARYLAND": "SKMD0",
    "MASSACHUSETTS": "SKMA0",
    "MICHIGAN": "SKMI0",
    "MISSOURI": "SKMO0",
    "NEVADA": "SKNV0",
    "NEW JERSEY": "SKNJ0",
    "OHIO": "SKOH0",
    "OKLAHOMA": "SKOK0",
    "PENNSYLVANIA": "SKPA0",
    "TENNESSEE": "SKTN2",
    "TEXAS": "SKTX0",
    "VIRGINIA": "SKVA0"

  },
  stateAetnaMapping: {
    "KENTUCKY": "128KY",
    "MARYLAND": "128MD",
    "NEW JERSEY": "46320",
    "OHIO": "50023",
    "TEXAS": "38692",
    "VIRGINIA": "128VA"
  }

}
