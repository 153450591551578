import { ElNotification } from "element-plus";

/**
 * success notification
 * @param {String} message
 * @param {String} title
 */
export function successNotification(
  message,
  title = "Success",
  duration = 2500
) {
  ElNotification.success({
    title: title,
    message: message,
    duration: duration,
  });
}

/**
 * error notification
 * @param {String} message
 * @param {String} title
 * @param position
 */
export function errorNotification(
  message,
  title = "Error",
  position = "top-right"
) {
  ElNotification.error({
    title: title,
    message: message,
    position: position,
    duration: 3000,
  });
}

/**
 * warning notification
 * @param {String} message
 * @param {String} title
 */
export function warningNotification(message, title = "Warning") {
  ElNotification.warning({
    title: title,
    message: message,
    duration: 3000,
  });
}

/**
 * info notification
 * @param {String} message
 * @param {String} title
 */
export function infoNotification(message, title = "Info") {
  ElNotification.info({
    title: title,
    message: message,
    duration: 3000,
  });
}
